<template>
  <div class="publish-wrap">
    <div style="display: flex; justify-content: space-between">
      <div>
        <!-- <p style="display: inline;margin-right:20px">标题：</p>
        <el-input
          v-model="content.title"
          placeholder="请输入文章标题"
          style="width: 600px"
        ></el-input> -->
      </div>
      <el-button class="style_button" type="text" @click="publishClick"
        >发布</el-button
      >
    </div>

      <!-- <div
        style="display: flex; margin: 20px 0"
        
      >
        <div>
          发布人：
          <el-input
          v-model="content.username"
          placeholder="请输入发布人"
          style="width: 600px"
        ></el-input>
        </div>
      </div> -->

    <div>
      <quill-editor
        class="editor"
        ref="myTextEditor"
        v-model="content.text"
        :options="editorOption"
        @change="onEditorChange($event)"
      ></quill-editor>
      <form action method="post" enctype="multipart/form-data" id="uploadFormMulti">
      <input
        style="display: none"
        :id="uniqueId"
        type="file"
        name="file"
        multiple
        accept="image/jpg, image/jpeg, image/png, image/gif"
        @change="uploadImg('uploadFormMulti')"
      />
    </form>
    </div>
  </div>
</template>

<script>
const toolbar = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  //   [{ script: "sub" }, { script: "super" }], // 上标/下标
  //   [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{'direction': 'rtl'}],                         // 文本方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  ["clean"], // 清除文本格式
  ["link", "image", "video"], // 链接、图片、视频
]; //工具菜单栏配置
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { BASE_URL } from "@/config";
import {
  getInfoById,
  updateInfo,
  addInfo,
  selectNoticeInfoByType,
} from "@/api/message";
import { Message } from "element-ui";

export default {
  name: "xxx",
  components: { quillEditor },
  computed: {
    //当前富文本实例
    editor() {
      return this.$refs.myTextEditor.quill;
    }
  },
  mounted() {
    this.action = BASE_URL + `/system/fileUpload`;
    this._selectNoticeInfoByType();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    this.type = this.$route.query.type;
    this.content.proid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
    this.id = this.$route.query.id;
  },
  data() {
    return {
      content: {
        username:'',
        title:'',
        proid:'',
        text:'',
        type:null,
      },
      perList:[],
      img1:'',
      uniqueId: "uniqueId",
      action: "",
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: {
        modules: {
          toolbar,
        },
      },
      type: 3,
      id: "",
    };
  },

  methods: {
    //获取列表
    _selectNoticeInfoByType() {
      selectNoticeInfoByType(this.page,this.pageSize,this.type).then(async (data) => {
        if (data.data == null) {
          this.page = 1;
          // await this._selectNoticeInfoByType();
          return;
        } else {
          this.content = data.data.list[0];
          this.id = this.content.id
          this._getInfoById(this.content.id)
        }
      });
    },
    _getInfoById(id) {
      //获取新闻信息
      getInfoById(id).then((data) => {
        this.content = data.data;
      });
    },
    onEditorChange(editor) {
      //富文本
      this.content.text = editor.html;
      this.$emit("content", editor.html);
    },
    
    publishClick() {
      //发布
      let res = this.perList.filter((val) => val == 68);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
        if(this.content.username === ''){
          Message.error("撰稿人员不能为空！");
          return
        }
        if(this.content.title === ''){
          Message.error("标题不能为空！");
          return
        }
        if(this.content.text === ''){
          Message.error("内容不能为空！");
          return
        }
        if (this.id !== undefined) {
          updateInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./notices`);
              Message.success("修改成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        } else {
          console.log(this.content)
          addInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./notices`);
              Message.success("发布成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
      }
        
      }
    },
  },
};
</script>
<style lang='less' scoped>
.publish-wrap {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
}
.editor {
  line-height: normal !important;
  height: 380px;
  margin-top: 20px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
.preview {
  display: flex;
  .avatar-uploader {
    border: 1px dashed #8c939d;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 240px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 240px;
    height: 140px;
    display: block;
  }
}
.style_button {
  width: 100px;
  height: 40px;
  background: #044bfe;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  margin-right: 100px;
}
</style>
