import myhttp from './http'

//获取所有通知消息
export function selectNoticeInfoByType(page,pageSize,type,conditions,proid) {
    return myhttp({
        url: "/notice/selectNoticeInfoByTypeList",
        method: "POST",
        params: { page,pageSize,type,conditions,proid }
    });
}
//根据id获取一篇新闻资讯文章
export function getInfoById(id) {
    return myhttp({
        url: "/notice/selectNoticeInfoByIdBg",
        method: "POST",
        params: { id }
    });
}


//增加一篇通知消息
export function addInfo(info) {
    return myhttp({
        url: "/notice/insertNoticeInfo",
        method: "POST",
        data: info
    });
}
//修改一篇通知消息
export function updateInfo(info) {
    return myhttp({
        url: "/notice/updateNoticeInfo",
        method: "POST",
        data: info
    });
}

//删除一篇通知消息
export function deleteInfo(id) {
    return myhttp({
        url: "/notice/deleteNoticeInfo",
        method: "POST",
        params: { id }
    });
}

//上传图片
export function uploadImages(file) {
    return myhttp({
        url: "/system/fileUpload",
        method: "POST",
        data: file
    });
}



